import { handleErrors } from './errors';
import { validateRecaptchaToken } from './recaptcha';
import type { Field, Settings, Value, _SFMCValue, _UnparsedError } from './types';

export async function submitWISDM(fields: Field[], token: string) {
  const url = emailPreferencesURL || window.app.urls.emailPreferenceUrl;

  const emailField = fields.find(field => field.type == 'email');

  if (!emailField || !emailField.value) {
    const error: _UnparsedError = {
      errorCode: 2,
      errorMessage: 'Email field required when method = WISDM',
    };

    throw error;
  }

  const email = emailField.value;

  const data = new FormData();
  data.append('OptStatus', 'I');
  data.append('EmailAddress', email);
  data.append('g-recaptcha-response', token);

  const response = await fetch(url, {
    method: 'POST',
    body: data,
  });

  return response;
}

function formatFieldData(field: Field): Value {
  const name = field.dataset.field;
  let value: string | number | boolean | undefined;

  if (field.tagName === 'DIV' || field.tagName === 'FIELDSET') {
    const radios = Array.from(field.querySelectorAll('input[type=radio]') as NodeListOf<HTMLInputElement>);
    const checked = radios.filter(radio => radio.checked)[0];

    if (checked) {
      value = checked.value;
    }
  } else {
    const type = field.type;

    if (type === 'checkbox' && field instanceof HTMLInputElement) {
      value = field.checked;
    } else {
      value = field.value;
    }
  }

  if (value === undefined) {
    value = '';
  }

  return { name, value };
}

export async function submitSFMC(fields: Field[], settings: Settings, token?: string) {
  const urls: { [key: string]: string } = {
    WWW: 'https://cloud.email.wwwincnews.com/form',
    BTS: 'https://cloud.email.batesfootwear.com/form',
    CAT: 'https://cloud.email.catfootwear.com/form',
    CHA: 'https://cloud.email.chacos.com/form',
    HDF: 'https://cloud.email.harley-davidsonfootwear.com/form',
    HUS: 'https://cloud.email.hushpuppies.com/form',
    HYT: 'https://cloud.email.hytest.com/form',
    KED: 'https://cloud.email.keds.com/form',
    MER: 'https://cloud.email.merrell.com/form',
    PRO: 'https://cloud.email.prokeds.com/form',
    SAU: 'https://cloud.email.saucony.com/form',
    SPE: 'https://cloud.email.sperry.com/form',
    WOL: 'https://cloud.email.wolverine.com/form',
  };

  const url = urls[settings.brand];

  const properties: _SFMCValue[] = fields
    .map(field => formatFieldData(field))
    .concat(settings.values || [])
    .map(item => {
      return {
        Name: item.name,
        Value: item.value,
      };
    });

  if (token) {
    const validationResults = await validateRecaptchaToken(token);
    settings.events.recaptchaVerification(validationResults);

    if (!validationResults.success) {
      const error: _UnparsedError = {
        errorCode: 4,
        errorMessage: 'Invalid reCAPTCHA token',
      };

      throw error;
    }
  }

  if (settings.submitRecaptchaToken && token) {
    properties.push({ Name: 'RecaptchaToken', Value: token });
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dataExtension: settings.dataExtension,
      properties,
    }),
  });

  const data = await response.json();

  if (data.status === 'Error') {
    if (!data.errorMessage && data.statusMessage) {
      data.errorMessage = data.statusMessage;
    }

    throw [
      {
        errorCode: data.errorCode,
        errorMessage: data.errorMessage,
      },
    ];
  }

  return response;
}

export async function handleSubmit(settings: Settings, fields: Field[], token?: string) {
  try {
    let response: any;

    if (settings.method === 'SFMC') {
      response = await submitSFMC(fields, settings, token);
    } else if (settings.method === 'WISDM') {
      response = await submitWISDM(fields, token);
    }

    if (settings.redirect) {
      window.location.href = settings.redirect;
    }

    settings.events.success(response);
  } catch (errors) {
    settings.internal.errors.push(...errors);
    handleErrors(settings.internal.errors, settings);
  }
}
