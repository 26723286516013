import type { ErrorClasses, Field, ParsedError, Settings, _UnparsedError } from './types';

export function handleErrors(errors: _UnparsedError[], settings: Settings) {
  const parsedErrors = showErrors(errors, settings);
  settings.events.error(parsedErrors);
}

export function parseError(error: _UnparsedError): ParsedError {
  if (error.errorMessage) {
    if (error.errorMessage.includes('Violation of PRIMARY KEY')) {
      error.errorCode = 10;
      error.errorMessage = 'Email address already exists';
    }

    if (error.errorMessage.includes('Cannot insert the value')) {
      const match = error.errorMessage.match(/column '(.+?)'/);

      if (match) {
        const name = match[1];
        error.errorCode = 11;
        error.errorMessage = `Provide a value for ${name}`;
      }
    }

    if (error.errorMessage.includes('CustomerKey')) {
      error.errorCode = 12;
      error.errorMessage = `Invalid data extension`;
    }

    if (error.errorMessage.includes('Errors occurred')) {
      error.errorCode = 13;
      error.errorMessage = 'Something went wrong';
    }
  }

  return {
    field: error.field,
    code: error.errorCode,
    message: error.errorMessage,
    handleError: error.handleError,
  };
}

function addErrorListItem(errorListItem: HTMLElement, settings: Settings) {
  if (!settings.errorListEl) {
    const errorListEl = document.createElement('ul');
    errorListEl.role = 'alert';
    errorListEl.classList.add(settings.errorListClass);

    settings.errorListEl = errorListEl;

    if (settings.errorPosition === 'bottom') {
      settings.errorEl.append(errorListEl);
    } else {
      settings.errorEl.prepend(errorListEl);
    }
  }

  settings.errorListEl.append(errorListItem);
}

export function showError(error: ParsedError, settings: Settings) {
  const field = error.field as Field;

  if (!field || settings.errorPosition === 'top' || settings.errorPosition === 'bottom') {
    const errorListItem = document.createElement('li');
    errorListItem.classList.add(settings.errorClass);
    errorListItem.innerHTML = error.message;

    if (field) {
      field.errorEl = errorListItem;
      field.classList.add(settings.errorFieldClass);
    }

    addErrorListItem(errorListItem, settings);
  } else {
    const errorDiv = document.createElement('div');
    errorDiv.role = 'alert';
    errorDiv.classList.add(settings.errorClass);
    errorDiv.innerHTML = error.message;

    if (error.handleError) {
      const classes: ErrorClasses = {
        errorClass: settings.errorClass,
        errorListClass: settings.errorListClass,
        errorFieldClass: settings.errorFieldClass,
      };

      error.handleError(field, error.message, errorDiv, classes);
    } else if (settings.errorPosition === 'before' || settings.errorPosition === 'after') {
      field.errorEl = errorDiv;
      field.classList.add(settings.errorFieldClass);
      field[settings.errorPosition](errorDiv);
    }
  }
}

export function showErrors(errors: _UnparsedError[], settings: Settings) {
  const parsedErrors = errors.map(error => parseError(error));

  parsedErrors.forEach(error => {
    showError(error, settings);
  });

  return parsedErrors;
}

export function removeErrorList(settings: Settings) {
  if (settings.errorListEl) {
    settings.errorListEl.remove();
    delete settings.errorListEl;
  }
}

export function removeFieldError(field: Field, settings: Settings) {
  if (field.errorEl) {
    field.errorEl.remove();
    delete field.errorEl;

    field.classList.remove(settings.errorFieldClass);
  }

  if (settings.errorListEl && settings.errorListEl.childNodes.length === 0) {
    settings.errorListEl.remove();
    delete settings.errorListEl;
  }

  field.classList.remove(settings.errorFieldClass);
}

export function removeAllErrors(fields: Field[], settings: Settings) {
  removeErrorList(settings);

  fields.forEach(field => {
    removeFieldError(field, settings);
  });

  settings.events.errorsRemoved();
}
