import isEmpty from 'validator/es/lib/isEmpty';
import isEmail from 'validator/es/lib/isEmail';
import isMobilePhone, { MobilePhoneLocale } from 'validator/es/lib/isMobilePhone';

import type {
  Field,
  FieldErrorMessages,
  Settings,
  _FieldError,
  _UnparsedError,
} from './types';

export function validate(field: Field): _FieldError {
  if (field instanceof HTMLDivElement || field instanceof HTMLFieldSetElement) {
    const radios = Array.from(
      field.querySelectorAll('input[type=radio]')
    ) as HTMLInputElement[];

    const hasRequired = !!radios.filter(radio => radio.required)[0];
    const hasChecked = !!radios.filter(radio => radio.checked)[0];

    if (hasRequired && !hasChecked) {
      return 'invalid';
    }
  } else {
    const type = field.type;
    const isRequired = field.required;

    if (type === 'checkbox' && field instanceof HTMLInputElement) {
      if (isRequired && !field.checked) {
        return 'invalid';
      }
    } else if (type === 'select-one' || type === 'select-multiple') {
      if (!isRequired) {
        return 'valid';
      }
      const disabled = field.querySelector('option[disabled]') as HTMLOptionElement;

      let value = field.value;

      if (type === 'select-multiple') {
        value = Array.from(field.querySelectorAll('option:checked'))
          .map(option => (option as HTMLOptionElement).value)
          .join(', ');
      }

      if (!value || (disabled && disabled.value === value)) {
        return 'invalid';
      }
    } else {
      const value = field.value || '';

      if (isEmpty(value)) {
        if (isRequired) {
          return 'empty';
        }
      } else {
        if (type === 'email') {
          if (!isEmail(value)) {
            return 'invalid';
          }
        }
        if (type === 'tel') {
          const locale = field.dataset.locale as MobilePhoneLocale;
          if (!isMobilePhone(value, locale)) {
            return 'invalid';
          }
        }
      }
    }
  }

  return 'valid';
}

export function validateField(field: Field, settings: Settings): _UnparsedError {
  const result = validate(field);

  const fieldName = field.dataset.field;

  let messages: FieldErrorMessages = {
      invalid: `${fieldName} is invalid`,
      empty: `${fieldName} is empty`,
    },
    handleError;

  if (settings.errors && Array.isArray(settings.errors)) {
    const customError = settings.errors.find(customErrorObj => {
      return customErrorObj.field === field.dataset.field;
    });

    if (customError) {
      messages = { ...messages, ...customError.messages };
      handleError = customError.handleError;
    }
  }

  if (result === 'empty') {
    field.classList.add(settings.errorFieldClass);

    return {
      field,
      errorCode: 0,
      errorMessage: messages.empty,
      handleError,
    };
  }

  if (result === 'invalid') {
    field.classList.add(settings.errorFieldClass);

    return {
      field,
      errorCode: 1,
      errorMessage: messages.invalid,
      handleError,
    };
  }
}

export function validateFields(fields: Field[], settings: Settings): _UnparsedError[] {
  return fields.map(field => validateField(field, settings)).filter(error => error);
}
