import { handleErrors, removeErrorList } from './errors';
import { Settings, _RecaptchaResults } from './types';

let recaptchaWidget: number;

export async function loadRecaptcha(
  element: HTMLElement,
  formName: string,
  sitekey: string,
  onSubmit: (token: string) => void,
  settings: Settings
) {
  const id = formName + '-recaptcha';

  async function waitForRecaptcha() {
    return new Promise(resolve => {
      const rate = 50;
      let elapsed = 0;

      const interval = setInterval(() => {
        elapsed += rate;

        if (
          window.grecaptcha &&
          window.grecaptcha.ready &&
          window.grecaptcha.render &&
          window.grecaptcha.reset &&
          window.grecaptcha.execute
        ) {
          clearInterval(interval);
          resolve(true);
        }
      }, rate);
    });
  }

  const start = new Date().getTime();
  const recaptchaIsLoaded = await waitForRecaptcha();
  const time = new Date().getTime() - start;

  if (recaptchaIsLoaded) {
    const recaptchaEl = document.createElement('div');
    recaptchaEl.id = id;
    recaptchaEl.classList.add('recaptcha');
    recaptchaEl.style.display = 'none';

    element.append(recaptchaEl);

    settings.recaptchaLoaded = true;
    settings.events.recaptchaLoaded(time);

    grecaptcha.ready(() => {
      recaptchaWidget = grecaptcha.render(recaptchaEl, {
        sitekey,
        size: 'invisible',
        callback: onSubmit,
        'error-callback': () => {
          removeErrorList(settings);
          handleErrors([{ errorCode: 5, errorMessage: 'reCAPTCHA error' }], settings);
        },
      });
    });
  }
}

export async function getRecaptchaToken() {
  grecaptcha.reset(recaptchaWidget);
  grecaptcha.execute(recaptchaWidget);
}

export async function validateRecaptchaToken(token: string): Promise<_RecaptchaResults> {
  const response = await fetch('https://cloud.email.wwwincnews.com/recaptcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  });

  const data = await response.json();

  return data;
}
