import { validateField, validateFields } from './lib/validate.js';
import { showErrors, removeFieldError, handleErrors } from './lib/errors.js';
import { loadRecaptcha, getRecaptchaToken } from './lib/recaptcha.js';
import { removeAllErrors } from './lib/errors.js';
import { handleSubmit } from './lib/submit.js';

import type {
  _FieldError,
  _SFMCValue,
  _UnparsedError,
  Events,
  Field,
  FieldErrorMessages,
  Form,
  Options,
  Settings,
} from './lib/types.js';

export type { _FieldError, _SFMCValue, _UnparsedError, Events, Field, FieldErrorMessages, Form, Options, Settings };

/**
 * __createForm__
 *
 * Creates a form object with methods for
 * submitting and validating & initializes
 * form on specified element
 *
 * @param settings.el - HTMLElement or string selector
 * @param options - Options object for form configuration
 * @returns Form object
 *
 * @public
 */
export default function createForm(el: HTMLElement | string, options: Options): Form {
  if (!window.emailPreferencesURL && !window.app.urls.emailPreferenceUrl) {
    throw new Error('No window.emailPreferencesURL or window.app.urls.emailPreferenceUrl found.');
  }

  if (typeof el === 'string') {
    el = document.querySelector(el) as HTMLElement;
  }

  const defaults = {
    brand: 'WWW',
    dataExtension: 'FED_Testing',
    el,
    errors: true,
    errorEl: el,
    errorClass: 'form-error',
    errorFieldClass: 'form-error-field',
    errorListClass: 'form-errors',
    errorPosition: 'top',
    events: {
      error() {},
      errorsRemoved() {},
      recaptchaSubmit() {},
      recaptchaLoaded() {},
      recaptchaVerification() {},
      submit() {},
      success() {},
    },
    internal: {
      errors: [] as _UnparsedError[],
      ready: true,
    },
    method: 'SFMC',
    recaptcha: false,
    recaptchaLoaded: false,
    recaptchaSiteKey: '6LfEEdAZAAAAAFkQnHboH4NGsRUUJAwLD4YtXoM2',
    submitEl:
      (el.querySelector('button[type=submit]') as HTMLButtonElement) ||
      (el.querySelector('input[type=submit]') as HTMLInputElement),
    submitRecaptchaToken: false,
    validateOnBlur: false,
    disableButtonWhileRecaptchaLoading: true,
  };

  const settings: Settings = Object.assign(defaults, options);

  if (!settings.el) {
    throw new Error('Could not find element');
  }

  if (!settings.el.dataset.name) {
    throw new Error('Form element must have data-name attribute');
  }

  const name = settings.el.dataset.name.replace(/[ \-\_]/g, '');

  const fields = Array.from(settings.el.querySelectorAll('[data-field]')) as Field[];

  if (!fields.length) {
    throw new Error('No fields found');
  }

  if (settings.method === 'SFMC') {
    if (!options || typeof options.dataExtension === 'undefined') {
      console.warn('No data extension provided. Using default: FED_Testing');
    }

    if (!options || typeof options.brand === 'undefined') {
      console.warn('No brand provided. Using default: WWW');
    }
  }

  fields.forEach(field => {
    if (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement) {
      field.addEventListener('keyup', event => {
        const { key } = event as KeyboardEvent;

        if (key === 'Enter') {
          event.preventDefault();
          submit();
        }
      });
    }

    function handleFieldChange() {
      const error = validateField(field, settings);

      if (settings.validateOnBlur === 'always') {
        removeFieldError(field, settings);

        if (error) {
          showErrors([error], settings);
        }
      } else if (settings.validateOnBlur === 'error') {
        if (field.errorEl && !error) {
          removeFieldError(field, settings);
        }
      }
    }

    if (field.type === 'checkbox' || field.type === 'select-one' || field.type === 'select-multiple') {
      field.addEventListener('change', () => {
        if (field.errorEl) {
          handleFieldChange();
        }
      });
    } else if (field instanceof HTMLDivElement || field instanceof HTMLFieldSetElement) {
      const radios = Array.from(field.querySelectorAll('input[type=radio]')) as Field[];

      radios.forEach(radio => {
        radio.addEventListener('change', () => {
          if (field.errorEl) {
            handleFieldChange();
          }
        });
      });
    } else {
      field.addEventListener('blur', handleFieldChange);
    }
  });

  async function submit() {
    if (!settings.internal.ready) return;

    settings.internal.ready = false;
    settings.internal.errors = [];

    removeAllErrors(fields, settings);
    settings.events.submit();

    settings.internal.errors.push(...validateFields(fields, settings));

    if (settings.recaptcha && !settings.recaptchaLoaded) {
      settings.internal.errors.push({ errorCode: 6, errorMessage: 'reCAPTCHA not initialized' });
    }

    if (settings.internal.errors.length) {
      const errors = Array.from(new Map(settings.internal.errors.map(item => [item['field'], item])).values());

      handleErrors(errors, settings);
      settings.internal.ready = true;

      return;
    }

    if (settings.recaptcha) {
      getRecaptchaToken();
    } else {
      await handleSubmit(settings, fields);
      settings.internal.ready = true;
    }
  }

  async function recaptchaCallback(token: string) {
    settings.events.recaptchaSubmit(token);
    await handleSubmit(settings, fields, token);
    settings.internal.ready = true;
  }

  if (settings.recaptcha) {
    settings.internal.ready = false;

    if (settings.disableButtonWhileRecaptchaLoading) {
      settings.submitEl.disabled = true;
    }

    loadRecaptcha(settings.el, name, settings.recaptchaSiteKey, recaptchaCallback, settings).then(() => {
      settings.internal.ready = true;

      if (settings.disableButtonWhileRecaptchaLoading) {
        settings.submitEl.disabled = false;
      }
    });
  }

  if (settings.submitEl) {
    settings.submitEl.addEventListener('click', submit);
  }

  return {
    el: settings.el,
    error(error: _UnparsedError, show: boolean): void {
      settings.internal.errors.push(error);

      if (show) {
        removeAllErrors(fields, settings);
        handleErrors([error], settings);
      }
    },
    name,
    settings,
    submit,
    on(event, func): void {
      settings.events[event] = func;
    },
  };
}
